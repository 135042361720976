<template>
<div class="container-fluid pb-5">
    <div class="row">
        <div class="col-12 pl-2 pr-4 pt-4 bg-accent">
            <div class="row px-2">

                <div class="col-lg-8">
                    <div class="toolbar mt-2">
                        <div class="btn-groupn" role="group" aria-label="Basic example">

                            <h3 class="vv">Customer Profile <span class="text-muted"> - {{customer.name}} {{customer.surname}}</span></h3>

                            <span class="text-muted">
                                {{customer.email}}
                            </span>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="col-12 bg-accent border-bottom">
            <ul class="nav nav-tabs px-0 mx-0" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab">Carts</a>
                </li>

            </ul>
        </div>
        <div class="col-12" v-show="ready">

            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active px-2 pt-4" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="card p-4 mb-4 bg-accent" v-for="cart in customer.carts" :key="cart.id">
                                <div class="row">
                                    <div class="col-6 ">
                                        <span class="text-muted text-bold">#{{cart.id}} </span>

                                    </div>
                                    <div class="col-6 text-right">

                                        <span class="text-right">{{humandate(cart.updated_at)}}</span>
                                    </div>
                                    <div class="col-12 pt-4">
                                        <div class="row">
                                            <div class="col-12 mb-3" v-for="cart_item in cart.cart_items" :key="cart_item.id">
                                                <img style="height: 38px !important; width: 38px !important" :blur="0" class="radius align-middle" :src="cart_item.thumbnail" />
                                                <span class="text-boldb mx-2">{{cart_item.title}}
                                                <span class="text-muted"> - {{cart_item.licence_name}}</span></span>
                                                <span class="float-right mx-2">${{cart_item.price}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade px-2 pt-4" id="customize" role="tabpanel">
                    <div class="row">
                        <div class="col-lg-8">
                            <span>wooo</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-lg-12 text-center pt-5 animate__animated animate__fadeIn" v-show="!ready">
            <span class="loading-lg  text-center mx-auto"></span>
            <br>
            <br>
            <span class="text-muted pt-5 mt-5">loading...</span>
        </div>
    </div>
</div>
</template>

<style>
@import "../assets/css/ql.css";
</style>

<script>
import moment from "moment"
import "node-snackbar/dist/snackbar.css";
import {
    VueEditor
} from "vue2-editor";
export default {
    name: "LicencesNew",
    components: {
        VueEditor,
    },
    data() {
        return {
            ready: false,
            saving: false,
            validationErrors: [],
            customer: {},
            customToolbar: [
                [{
                    'header': [1, 2, 3, 4, 5, 6, false]
                }],
                ["bold", "italic", "underline"],
                [{
                    'align': []
                }],
                [{
                    list: "ordered"
                }, {
                    list: "bullet"
                }]
            ],

        };
    },
    mounted() {
        //this.ready = false;

        this.get_customer();

        //$('#file_modal').modal('show')
    },
    methods: {
        humandate(stime) {

            return moment(stime).format('ll');
        },
        get_customer() {
            if (this.$route.params.id) {
                var baseURI =
                    this.$baseurl + "/customers/" + this.$route.params.id;
                var config = {
                    Authorization: "Bearer " + this.$store.getters.getUser.token,
                };

                this.$http
                    .get(baseURI, {
                        headers: config,
                    })
                    .then((result) => {
                        if (result.data.message === "invalid token") {} else {}
                        if (result.data) {
                            this.customer = result.data
                        }

                        //this.keys = Object.keys(this.res[0]);
                    });
                this.ready = true;

            } else {
                this.ready = true;
            }
        },
        cancel() {
            this.$router.push("/d/customers");
        },

    },
};
</script>

<style>
</style>
